import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import { emitter } from '../../../../scripts'
import { compressImage } from '../../../../scripts/helper/compress-image/compress-image'
import { logging } from '../../../../scripts'
// import type { EssenszuschussUploadConfig } from './EssenszuschussUpload.shared'

export const identifier = 'essenszuschuss-upload'

const EssenszuschussUploadComponent: TComponentInit = (element) => {
	const fileInput = element.querySelector<HTMLInputElement>(
		'[data-essenszuschuss-upload-file-input]',
	)
	// const config: EssenszuschussUploadConfig = JSON.parse(
	// 	element.getAttribute('data-component-config')!,
	// )
	const form = element.querySelector<HTMLFormElement>('form')
	// The form can receive files via events and we can't sync file inputs
	// therefore we need to hold the current file in a variable
	let files: FileList | null | undefined = fileInput?.files

	const onFileChange = () => {
		element.classList.toggle(`${identifier}--valid`, !!files?.length)
		element.classList.toggle(`${identifier}--invalid`, !files?.length)
		emitter.emit('imagePreview:change', files ?? null)
	}

	const onChange = () => {
		files = fileInput?.files
		onFileChange()
	}

	const onExternalFileChange = (newFiles: FileList | null) => {
		files = newFiles
		onFileChange()
	}

	const onSubmit = async (event: Event) => {
		if (!form || !files) {
			return
		}

		event.preventDefault()
		emitter.emit('loading:start')

		try {
			const formData = new FormData(form)
			formData.delete('file')

			for (const file of files) {
				// let fileSize = file.size
				const method =
					file.type.match(/image\/png/) !== null ? 'size' : 'quality'

				try {
					const compressedFile = await compressImage(file, 500000, method, 0.8)
					// fileSize = compressedFile.size

					console.log({ file, compressedFile })

					formData.append(
						file.name,
						new File([compressedFile], file.name, {
							type: file.type,
						}),
					)
				} catch (error) {
					logging.logError({
						name: 'Could not compress file',
						description: (error as Error).message,
					})

					throw new Error('compressionError')
				}

				formData.append('file', file)
			}

			console.log(formData.entries())

			const response = await fetch(form.action, {
				method: 'POST',
				headers: {
					'HX-Request': 'true',
				},
				body: formData,
				redirect: 'follow',
			})

			console.log(response)
			if (!response.ok) {
				throw Error('uploadError')
			}

			const responseText = await response.text()

			emitter.emit('essenszuschussUploadOverlay:form-loaded', responseText)
			// window.location.href = config.redirectTarget
		} catch (error) {
			logging.logError({
				name: 'Upload Fehlgeschlagen!',
				description: (error as Error).message,
			})
		}

		emitter.emit('loading:stop')
	}

	const init = () => {
		emitter.on('essenszuschussUpload:file-change', onExternalFileChange)
		fileInput?.removeAttribute('required')
		fileInput?.addEventListener('change', onChange)
		form?.addEventListener('submit', onSubmit)

		onFileChange()
	}

	function destroy() {
		emitter.off('essenszuschussUpload:file-change', onExternalFileChange)
		fileInput?.setAttribute('required', 'required')
		fileInput?.removeEventListener('change', onChange)
		form?.removeEventListener('submit', onSubmit)
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, EssenszuschussUploadComponent)

export default {
	identifier,
	EssenszuschussUploadComponent,
}
