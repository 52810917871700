const canvas = document.createElement('canvas')
const ctx = canvas.getContext('2d')

// immediately use this value as maximum image width
const maxImageWidth = 2000

// the size in px to reduce the image,
// if compression method size is used
const sizeReductionStep = 200
const minImageWidth = 1000

const qualityReductionStep = 0.1
const minQuality = 0.7

/**
 *
 *
 * @param {(File|Blob)} image
 * @return {*}  {Promise<HTMLImageElement>}
 */
const loadImage = async (image: File | Blob) => {
	return await new Promise<HTMLImageElement>((resolve) => {
		const imageElement = document.createElement('img')
		const imageUrl = window.URL.createObjectURL(image)
		imageElement.onload = () => {
			window.URL.revokeObjectURL(imageUrl)
			resolve(imageElement)
		}
		imageElement.src = imageUrl
	})
}

const compress = async (
	originalImageElement: HTMLImageElement,
	imageFile: File | Blob,
	initialSize: number,
	targetSize: number,
	method: 'quality' | 'size',
	quality: number,
	imageWidth: number,
): Promise<File | Blob> => {
	// not able to compress
	if (ctx === null) {
		return imageFile
	}

	const imageHeight =
		(originalImageElement.height * imageWidth) / originalImageElement.width

	canvas.width = imageWidth
	canvas.height = imageHeight

	// console.log('compress', {imageWidth, imageHeight, quality, method})

	// break the recursion,
	// no further compression possible
	if (
		quality <= minQuality ||
		(method === 'size' && canvas.width <= minImageWidth)
	) {
		// console.log('no further compression possible')
		return imageFile
	}

	ctx.drawImage(originalImageElement, 0, 0, imageWidth, imageHeight)

	const compressedImageFile: File | Blob = await new Promise((resolve) => {
		canvas.toBlob(
			(blob) => {
				if (blob === null) {
					resolve(imageFile)

					return
				}

				resolve(blob as File)
			},
			imageFile.type,
			quality,
		)
	})

	// console.log('asdfasdf', {compressedImageFile, method, quality})

	// if the size is still the same after compression with size method
	// the image can't be compressed
	if (compressedImageFile.size === imageFile.size && method === 'size') {
		// console.log('image can not be compressed', compressedImageFile.size, imageFile.size)
		return compressedImageFile
	}

	// Sometimes the file gets bigger after using
	// quality based compression, which can lead to
	// bigger results, if the image is already to
	// small in width
	if (
		imageFile.size < compressedImageFile.size &&
		originalImageElement.width <= minImageWidth
	) {
		// console.log('image size enlarged', compressedImageFile.size, imageFile.size)
		return imageFile
	}

	// try resize instead of quality compression
	// if the size is still the same after compression
	if (
		(compressedImageFile.size === imageFile.size && method === 'quality') ||
		(quality <= minQuality && method === 'quality')
	) {
		// console.log('Switch to size', {
		// 	size: compressedImageFile.size,
		// 	imageWidth,
		// 	quality,
		// })
		method = 'size'
	}

	// retry compression with lower quality and the same method
	// if the image is still too big
	if (compressedImageFile.size > targetSize) {
		// reduce the width and height of the image
		// if method 'size' is used
		imageWidth = method === 'size' ? imageWidth - sizeReductionStep : imageWidth
		imageWidth = Math.max(imageWidth, minImageWidth)
		imageWidth = Math.min(originalImageElement.width, imageWidth)
		quality = method === 'quality' ? quality - qualityReductionStep : quality
		quality = Math.max(quality, minQuality)

		// console.log('retry compression', {
		// 	size: compressedImageFile.size,
		// 	imageWidth,
		// 	quality,
		// })
		return await compress(
			originalImageElement,
			compressedImageFile,
			initialSize,
			targetSize,
			method,
			quality,
			imageWidth,
		)
	}

	// console.log('return', compressedImageFile.size, imageFile.size)
	return compressedImageFile
}

/**
 *
 *
 * @export
 * @param {(File | Blob)} image
 * @param {number} targetSize Image size in bytes to compress to
 * @param {('quality' | 'size')} method Used method to compress the image
 * @param {number} quality The quality to start the compression with
 * @param {number} width The image width to start the compression with
 * @return {*}  {Promise<File | Blob>}
 */
export const compressImage = async (
	image: File | Blob,
	targetSize: number,
	method: 'quality' | 'size',
	quality: number,
	width = maxImageWidth,
): Promise<File | Blob> => {
	// console.log(image, image.size, targetSize, method, quality)

	// image is already small enough
	if (image.size <= targetSize) {
		// console.log('return size <= targetSize')
		return image
	}

	const imageElement = await loadImage(image)

	return await compress(
		imageElement,
		image,
		image.size,
		targetSize,
		method,
		quality,
		Math.min(imageElement.width, width),
	)
}
